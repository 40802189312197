import React, { useCallback } from "react";
import { Box, Paper } from "@mui/material";
import { useListRegionsQuery } from "../../util/client/TcApiV2";
import _ from "lodash";
import { UserDashboard } from "./UserDashboard";

const DashboardPage = () => {
  const { data: regions, refetch } = useListRegionsQuery({
    queryParams: { type: "own" },
  });

  const onRefresh = useCallback(() => refetch(), [refetch]);

  return (
    <Paper sx={{ minHeight: "100vh", width: "100%", overflowY: "auto" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: 2,
        }}
      >
        {regions && <UserDashboard regions={regions} onRefresh={onRefresh} />}
      </Box>
    </Paper>
  );
};

export default DashboardPage;
