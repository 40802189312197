import { TcApi } from "../types/TcApi";
import { TcEndpoint } from "./types";

const login = new TcEndpoint<
  TcApi.LoginSignupResponse,
  never,
  never,
  TcApi.LoginSignupRequest
>({
  endpointName: "login",
  path: "/api/auth/login",
  method: "POST",
});

const signup = new TcEndpoint<
  TcApi.LoginSignupResponse,
  never,
  never,
  TcApi.LoginSignupRequest
>({
  endpointName: "signup",
  path: "/api/auth/signup",
  method: "POST",
});

const changePassword = new TcEndpoint<
  TcApi.LoginSignupResponse,
  never,
  never,
  TcApi.LoginSignupRequest
>({
  endpointName: "changePassword",
  path: "/api/auth/change-pw",
  method: "POST",
});

const resetPassword = new TcEndpoint<
  TcApi.LoginSignupResponse,
  never,
  never,
  TcApi.LoginSignupRequest
>({
  endpointName: "resetPassword",
  path: "/api/auth/reset-pw",
  method: "POST",
});

const verifyToken = new TcEndpoint<unknown, never, never, never>({
  endpointName: "verifyToken",
  path: "/api/auth/verify",
  method: "POST",
});

export const authModule = {
  login,
  signup,
  changePassword,
  resetPassword,
  verifyToken,
};
