import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, Routes, Route, Link, useSearchParams } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import { EditMapPage, ViewMapPage } from "./pages/map/MapPage";
import BarcodePage from "./pages/barcode/BarcodePage";
import LoginPage from "./pages/login/LoginPage";
import RequireAuth from "./util/auth/RequireAuth";
import DashboardPage from "./pages/dashboard/DashboardPage";
import AuthProvider from "./util/auth/AuthProvider";
import { Permissions } from "./lib";
import PermGuard from "./util/auth/PermGuard";
import SandboxPage from "./pages/sandbox/SandboxPage";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";

// router with auth example https://github.com/remix-run/react-router/tree/dev/examples/auth/src

const signOut = () => {};

const Layout = () => {
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const [searchParams] = useSearchParams();
  const hideToolbar = (searchParams.get("toolbar") || "") === "hide";

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!hideToolbar && (
        <Box>
          <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <Toolbar>
              <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                <Link
                  to={token ? "/dashboard" : "/"}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Trail Companion
                </Link>
              </Typography>
              {
                token ? (
                  <Button color="inherit" onClick={() => signOut()}>
                    Logout
                  </Button>
                ) : undefined
                // <Button color="inherit" onClick={() => navigate("/login")}>
                //   Log in
                // </Button>
              }
            </Toolbar>
          </AppBar>
        </Box>
      )}

      <Box
        sx={{
          height: "100vh",
          maxHeight: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!hideToolbar && <Toolbar />}
        <Outlet />
      </Box>
    </Box>
  );
};
type Route = {
  path: string;
  requireLogin: boolean;
  Element: () => JSX.Element;
  perms?: Permissions.Perm;
};
const routes: Route[] = [
  { path: "/", requireLogin: false, Element: HomePage },
  { path: "/login", requireLogin: false, Element: LoginPage },
  { path: "/dashboard", requireLogin: true, Element: DashboardPage },
  {
    path: "/map/:regionId",
    requireLogin: false,
    Element: ViewMapPage,
  },
  {
    path: "/map/:regionId/edit",
    requireLogin: true,
    Element: EditMapPage,
    // perms: PERMISSIONS.REGION_UPDATE,
  },
  { path: "/barcode", requireLogin: true, Element: BarcodePage },
  { path: "/sandbox", requireLogin: false, Element: SandboxPage },
];
function App() {
  return (
    <AuthProvider>
      <Box sx={{ width: "100%", minHeight: "100%" }}>
        <Routes>
          <Route element={<Layout />}>
            {routes.map(({ path, requireLogin, Element, perms }) => (
              <Route
                key={path}
                path={path}
                element={
                  requireLogin ? (
                    <RequireAuth>
                      {perms ? (
                        <PermGuard>
                          <Element />
                        </PermGuard>
                      ) : (
                        <Element />
                      )}
                    </RequireAuth>
                  ) : (
                    <Element />
                  )
                }
              />
            ))}
          </Route>
        </Routes>
      </Box>
    </AuthProvider>
  );
}

export default App;
