import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useLoginMutation, useSignupMutation } from "../../util/client/TcApiV2";
import { useEffect } from "react";
import { setToken } from "../../util/auth/authSlice";

export const LOCALSTORAGE_TOKEN_KEY = "tcToken";

export const useAuth = () => {
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const [doLogin, { data: loginData, isLoading: isLoginLoading }] =
    useLoginMutation();
  const [doSignup, { data: signupData, isLoading: isSignupLoading }] =
    useSignupMutation();

  const authResponse = loginData || signupData;
  const isLoading = isLoginLoading || isSignupLoading;
  const dispatch = useDispatch();
  const doAuthAction = (
    action: "login" | "signup",
    email: string,
    password: string
  ) => {
    console.log("Doing action", action);
    if (action === "login") {
      doLogin({ payload: { username: email, password } });
    }
    if (action === "signup") {
      doSignup({ payload: { username: email, password } });
    }
  };

  useEffect(() => {
    if (authResponse) {
      console.log(`auth! ${authResponse}`, authResponse.token);
      dispatch(setToken(authResponse.token));
      localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, authResponse.token);
    }
  }, [authResponse]);
  const isAuthenticated = !!token;
  return { token, setToken, doAuthAction, isLoading, isAuthenticated };
};
