import {
  Alert,
  Box,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Snackbar,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { Drt } from "../../lib/Drt";
import TrailListItem from "./TrailListItem";
import TrailDropzone from "./TrailDropzone";
import EditRegionPropertiesButton from "./EditRegionPropertiesButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SaveIcon from "@mui/icons-material/Save";
import { DeviceApi } from "../../lib";
import { useSearchParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SelectableList from "../../components/SelectableList";
import { useUpdateRegionMapMutation } from "../../util/client/TcApiV2";

type TrailListProps = {
  region: Drt.Region;
  regionId: string;
  selectedTrailId?: string;
  isEditMode: boolean;
  selectedGeometryId?: string;
  onViewTrailClick: (trailId: string | undefined) => void;
  onEditTrailProperties: (
    trailId: string,
    props: Partial<Drt.TrailProperties>
  ) => void;
  onDeleteTrail: (trailId: string) => void;
  onSelectTrailGeometry: (trailId: string, geometryId: string) => void;
  onReorderTrail: (fromIndex: number, toIndex: number) => void;
  onDeleteTrailGeometry: (trailId: string, geometryId: string) => void;
  onAddTrail: (trail: Drt.Trail) => void;
  onAddMarker: (trailId: string) => void;
  onEditRegionProps: (
    regionProps: Drt.RegionProperties & {
      regionName: string;
    }
  ) => void;
  onDrawerClose: () => void;
  isDrawerOpen: boolean;
  deviceHistory?: DeviceApi.GetDeviceLogResponse;
  selectedHistoryIndex?: number;
  selectHistoryItem: (index?: number) => void;
};

const TrailList = ({
  region,
  regionId,
  onViewTrailClick,
  selectedTrailId,
  isEditMode,
  onEditTrailProperties,
  onDeleteTrail,
  onSelectTrailGeometry,
  onReorderTrail,
  onDeleteTrailGeometry,
  selectedGeometryId,
  onAddTrail,
  onAddMarker,
  onEditRegionProps,
  onDrawerClose,
  isDrawerOpen,
  deviceHistory,
  selectedHistoryIndex,
  selectHistoryItem,
}: TrailListProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const hideToolbar = (searchParams.get("toolbar") || "") === "hide";

  const handleCloseSnackbar = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const onReorderTrailById = (trailId: string, direction: number) => {
    const index = region.features.findIndex(
      (trail) => trail.properties.id === trailId
    );
    const newIndex = index + direction;
    //POI trail stays put and is always index 0!
    if (index >= 0 && newIndex >= 1 && newIndex < region.features.length) {
      onReorderTrail(index, newIndex);
    }
  };

  // TODO: move to MapPage
  const [
    triggerUpdateRegion,
    { isLoading: isSaving, isError: isSaveError, isSuccess: isSaveSuccess },
  ] = useUpdateRegionMapMutation();
  useEffect(() => {
    if (isSaveError || isSaveSuccess) {
      setSnackbarOpen(true);
    }
  }, [isSaveError, isSaveSuccess]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Drawer
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: isDesktop ? 450 : "100%",
        },
        flexShrink: 0,
      }}
      open={isDesktop || isDrawerOpen}
    >
      {/* Since we're in a drawer, we want to render an extra toolbar only if we are showing the appbar */}
      {!hideToolbar && <Toolbar />}
      <Toolbar>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
          {region.name}
        </Typography>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={onDrawerClose}
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      {deviceHistory && (
        <>
          <Box p={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Device History"
                value={dayjs(searchParams.get("date"))}
                sx={{ width: "100%", mb: 1 }}
                onChange={(newValue) => {
                  selectHistoryItem(undefined);
                  setSearchParams(
                    { date: newValue?.format("YYYY-MM-DD") || "" },
                    { replace: true }
                  );
                }}
              />
            </LocalizationProvider>
            <SelectableList
              items={deviceHistory.map((item, index) => ({
                id: `${index}`,
                name: `${item.lat}, ${item.lon} ${Math.floor(
                  item.altitude * 3.281
                )}ft ${Math.floor(item.speed * 2.237)}mph`,
              }))}
              selectedItem={`${selectedHistoryIndex}`}
              onSelectItem={(index) => selectHistoryItem(parseInt(index))}
            />
          </Box>
          <Divider />
        </>
      )}
      <Box sx={{}}>
        {isEditMode && (
          <>
            <EditRegionPropertiesButton
              regionProps={{ ...region.properties, regionName: region.name }}
              onSubmitChanges={onEditRegionProps}
            />
            <LoadingButton
              size="small"
              color="primary"
              onClick={() => {
                triggerUpdateRegion({
                  pathParams: { regionId },
                  payload: region,
                });
              }}
              loading={isSaving}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{ mt: 6 }}
            >
              {isSaveError ? (
                <Alert
                  onClose={handleCloseSnackbar}
                  severity="error"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  Error saving region. Please try again later.
                </Alert>
              ) : (
                <Alert
                  onClose={handleCloseSnackbar}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  Saved Successfully!
                </Alert>
              )}
            </Snackbar>
          </>
        )}
        {region.features.map((trail) =>
          !isEditMode && trail.properties.name === "POI" ? undefined : (
            <TrailListItem
              trail={trail}
              key={trail.properties.id}
              onViewTrailClick={onViewTrailClick}
              isSelected={selectedTrailId === trail.properties.id}
              isEditMode={isEditMode}
              onEditTrailProperties={onEditTrailProperties}
              onDeleteTrail={onDeleteTrail}
              onSelectTrailGeometry={onSelectTrailGeometry}
              onReorderTrail={onReorderTrailById}
              selectedGeometryId={selectedGeometryId}
              onDeleteTrailGeometry={onDeleteTrailGeometry}
              onAddMarker={onAddMarker}
            />
          )
        )}
        {isEditMode && <TrailDropzone onAddTrail={onAddTrail} />}
      </Box>
    </Drawer>
  );
};

export default TrailList;
