// @ts-nocheck
import * as _ from "lodash";
import { regionModule } from "./region";
import { ApiConfig, ApiModule, TcEndpoint } from "./types";
import { authModule } from "./auth";

const getFlatEndpointsFromModule = (
  module: ApiModule
): TcEndpoint<any, any, any, any>[] => {
  const items = Object.keys(module).flatMap((moduleOrEndpointName) => {
    const moduleOrEndpoint = module[moduleOrEndpointName];
    if (moduleOrEndpoint instanceof TcEndpoint) {
      return [moduleOrEndpoint];
    } else {
      return getFlatEndpointsFromModule(moduleOrEndpoint);
    }
  });
  return items;
};

const getFlatEndpointsFromApiConfig = (
  api: ApiConfig
): TcEndpoint<any, any, any, any>[] => {
  return Object.keys(api).flatMap((moduleName) => {
    const module = api[moduleName];
    return getFlatEndpointsFromModule(module);
  });
};

export const tcEndpoints = {
  region: regionModule,
  auth: authModule,
} as const;

export const tcEndpointList = getFlatEndpointsFromApiConfig(tcEndpoints);

// TODO search through flat endpoint list for dupes and throw if a dupe is found
// this will prevent us from accidentally forgetting to change the endpoint name
