import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import DashboardCard from "./DashboardCard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ApiCommon } from "../../lib";

type RegionCardProps = {
  region: ApiCommon.RegionItem;
  onRefresh: () => void;
};
const RegionCard = ({ region, onRefresh }: RegionCardProps) => {
  // const [triggerCopyRegion] = useCopyRegionMutation();
  const onClickClone = useCallback(() => {
    console.log("TODO impl copy region");
  }, []);
  return (
    <DashboardCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography>{region.name}</Typography>
        <Box sx={{ display: "flex" }}>
          <Link to={`/map/${region.id}`}>
            <IconButton aria-label="view" color="primary">
              <VisibilityIcon />
            </IconButton>
          </Link>
          <Link to={`/map/${region.id}/edit`}>
            <IconButton aria-label="edit" color="primary">
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton onClick={onClickClone} aria-label="copy" color="primary">
            <ContentCopyIcon />
          </IconButton>
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default RegionCard;
