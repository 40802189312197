import {
  Box,
  Button,
  Card,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/hooks/useAuth";

const actionMap = {
  signup: "Sign up",
  login: "Log in",
} as const;

const LoginPage = () => {
  const [action, setAction] = useState<"login" | "signup">("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { doAuthAction, isAuthenticated, isLoading } = useAuth();
  const onActionClick = () => {
    doAuthAction(action, email, password);
  };
  useEffect(() => {
    console.log("Token change", isAuthenticated);
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  const onActionChange = (
    event: React.MouseEvent<HTMLElement>,
    newAction: string | null
  ) => {
    if (newAction) {
      setAction(newAction as "login" | "signup");
    }
  };
  return (
    <Paper sx={{ minHeight: "100vh", width: "100%", pt: 8 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 2,
        }}
      >
        <Card sx={{ p: 3, backgroundColor: "#ffffff", minWidth: 500 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ToggleButtonGroup
              value={action}
              exclusive
              onChange={onActionChange}
              aria-label="text alignment"
            >
              <ToggleButton
                value="login"
                aria-label="log in"
                sx={{ flexGrow: 1 }}
              >
                Login
              </ToggleButton>
              <ToggleButton
                value="signup"
                aria-label="sign up"
                sx={{ flexGrow: 1 }}
              >
                Sign Up
              </ToggleButton>
            </ToggleButtonGroup>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={onActionClick}
                disabled={isLoading}
              >
                {actionMap[action]}
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </Paper>
  );
};

export default LoginPage;
