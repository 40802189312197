import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { tcEndpoints } from "@trailcompanion/tc-common/src/endpoints";

export const tcApiV2 = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state?.auth?.accessToken;
      headers.set("authorization", `Bearer ${token}`);
    },
  }),
  endpoints: (builder) => ({}),
});

export const authApi = tcApiV2.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    login: builder.mutation<
      ReturnType<typeof tcEndpoints.auth.login.requestFn>,
      Parameters<typeof tcEndpoints.auth.login.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.auth.login.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.auth.login.method,
        body: req.payload,
      }),
    }),
    signup: builder.mutation<
      ReturnType<typeof tcEndpoints.auth.signup.requestFn>,
      Parameters<typeof tcEndpoints.auth.signup.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.auth.signup.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.auth.signup.method,
        body: req.payload,
      }),
    }),
  }),
});

export const regionApi = tcApiV2.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    listRegions: builder.query<
      ReturnType<typeof tcEndpoints.region.listRegions.requestFn>,
      Parameters<typeof tcEndpoints.region.listRegions.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.listRegions.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.listRegions.method,
        body: req.payload,
      }),
    }),
    getRegionMap: builder.query<
      ReturnType<typeof tcEndpoints.region.getRegionMap.requestFn>,
      Parameters<typeof tcEndpoints.region.getRegionMap.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.getRegionMap.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.getRegionMap.method,
        body: req.payload,
      }),
    }),
    createRegion: builder.mutation<
      ReturnType<typeof tcEndpoints.region.createRegion.requestFn>,
      Parameters<typeof tcEndpoints.region.createRegion.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.createRegion.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.createRegion.method,
        body: req.payload,
      }),
    }),
    updateRegionMap: builder.mutation<
      ReturnType<typeof tcEndpoints.region.updateRegionMap.requestFn>,
      Parameters<typeof tcEndpoints.region.updateRegionMap.requestFn>[0]
    >({
      query: (req) => ({
        url: tcEndpoints.region.updateRegionMap.getUrl(req.pathParams),
        params: req.queryParams,
        method: tcEndpoints.region.updateRegionMap.method,
        body: req.payload,
      }),
    }),
  }),
});

// export const regionApi = tcApiV2.injectEndpoints({
//   overrideExisting: false,
//   endpoints: (builder) => ({}),
// });

export const { useLoginMutation, useSignupMutation } = authApi;
export const {
  useListRegionsQuery,
  useLazyGetRegionMapQuery,
  useCreateRegionMutation,
  useUpdateRegionMapMutation,
} = regionApi;
