import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "./authSlice";
import { LOCALSTORAGE_TOKEN_KEY } from "../../auth/hooks/useAuth";
import { useSearchParams } from "react-router-dom";

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  return <AuthProviderInternal>{children}</AuthProviderInternal>;
};

const AuthProviderInternal = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const [isLoaded, setLoaded] = useState(false);
  const [searchParams] = useSearchParams();
  const qpToken = decodeURIComponent(searchParams.get("token") || "");
  console.log(qpToken);
  useEffect(() => {
    const lsToken = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
    if (qpToken) {
      dispatch(setToken(qpToken));
    } else if (lsToken) {
      dispatch(setToken(lsToken));
    } else {
      dispatch(setToken(undefined));
    }
    setLoaded(true);
  }, []);

  // Don't show anything until we know whether or not a token exists in localStorage
  if (!isLoaded) {
    return <></>;
  }
  return children;
};

export default AuthProvider;
